import React from 'react';
import FoodForm from './components/FoodForm';
import './index.css';

function App() {
  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <h1 className="text-4xl font-bold text-center text-blue-600 mb-6">Welcome to Youti!</h1>
      <FoodForm />
    </div>
  );
}

export default App;