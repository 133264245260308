import React, { useState } from 'react';

const FoodForm = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [error, setError] = useState('');

    const handleLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                    setError('');
                },
                (error) => {
                    console.error('Error getting location:', error);
                    setError('Unable to retrieve location');
                }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const newFoodPost = {
            title,
            description,
            price,
            latitude,
            longitude,
        };

        console.log('New Food Post:', newFoodPost);

        // Clear form fields
        setTitle('');
        setDescription('');
        setPrice('');
        setLatitude('');
        setLongitude('');
    };

    return (
        <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md space-y-4">
            <h2 className="text-2xl font-bold mb-4">Add Food Post</h2>

            <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title"
                className="w-full p-2 border rounded"
                required
            />

            <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
                className="w-full p-2 border rounded"
                required
            />

            <input
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                placeholder="Price"
                className="w-full p-2 border rounded"
            />

            <button
                type="button"
                onClick={handleLocation}
                className="bg-blue-500 text-white p-2 rounded"
            >
                Use Current Location
            </button>

            {error && <p className="text-red-500">{error}</p>}

            <div className="flex justify-between">
                <div>
                    <label>Latitude:</label>
                    <input
                        type="text"
                        value={latitude}
                        readOnly
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div>
                    <label>Longitude:</label>
                    <input
                        type="text"
                        value={longitude}
                        readOnly
                        className="w-full p-2 border rounded"
                    />
                </div>
            </div>

            <button
                type="submit"
                className="bg-green-500 text-white p-2 rounded"
            >
                Submit
            </button>
        </form>
    );
};

export default FoodForm;